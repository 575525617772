/* components/InteractiveText.css */

.interactive-text {
    position: relative;
  }
  
  .hoverable-word {
    cursor: pointer;
    display: inline-block;
    position: relative;
  }
  
  .hoverable-word:hover {
    background-color: #f0f0f0;
    padding: 2px 5px;
    border-radius: 5px;
  }
  
  .hoverable-word:hover .phoneme {
    color: #666; /* Lighter color for phoneme text */
  }
  
  .phoneme {
    color: #000; /* Default color, adjust as needed */
  }  